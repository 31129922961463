<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button class="vd_export" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()">刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        v-loading="loadFlag"
        :columns="tableProperties"
        :data="tableData"
        :need-fixed-height="true"
        :tableRowKey="'DTdeli_id'"
        :totalPage="totalPage"
        @getTableData="getDTdeliList"
        @row-dblclick="dbClickJp"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/TransportationManagement/DTdeliManage/dtdeli';
import { DTdeliAPI } from '@api/modules/DTdeli';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ContractList',
  components: {
    SearchTable
  },
  data() {
    return {
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentPage: 1
    };
  },
  mounted() {
    this.getDTdeliList();
  },
  methods: {
    initData() {
      this.getDTdeliList();
    },
    async getDTdeliList() {
      await (this.loadFlag = true);
      await DTdeliAPI.getDelis(this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getDTdeliList();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/DTdeli_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.DTdeli_id
          })
        )
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
